import axios from 'axios';
import UAParser from 'ua-parser-js';

export const log = (message, extraLogInfo, logType, encrypt = false) => {
  const uaParser = new UAParser();
  const type = logType || 'info';
  const context = {
    url: window.location.href,
    env: {
      browser: uaParser.getBrowser(),
      os: uaParser.getOS(),
      engine: uaParser.getEngine(),
    },
    type,
    message,
    encrypt,
    data: extraLogInfo,
  };

  return axios.post('/logged_client_errors', {
    base64_data: btoa(JSON.stringify(context)),
  });
};

export const logError = (message, extraLogInfo, encrypt = false) => log(message, extraLogInfo, 'error', encrypt);
